import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-list-heading',
  templateUrl: './list-heading.component.html',
  styleUrls: ['./list-heading.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ListHeadingComponent implements OnInit {

  constructor() { }


  ngOnInit(): void {
  }

}
