import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatIconRegistry } from '@angular/material/icon';
import { HttpClient, HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';
import { ApiModule } from './modules/api/api.module';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LanguageService } from './modules/config/language/language.service';
import { FormPageService } from './components/form-page/form-page.service';
import { UserService } from './modules/user/user.service';
import { ConfigModule } from './modules/config/config.module';
import { GeneralConfigService } from './modules/config/general/general-config.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { UpdateDialogComponent } from './components/update-dialog/update-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { UpdateDialogModule } from './components/update-dialog/update-dialog.module';
import { OfflineService } from './modules/offline/offline.service';


export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ConfigModule,

    UpdateDialogModule,

    HttpClientXsrfModule.withOptions({
      cookieName: 'XSRF-TOKEN',
      headerName: 'X-XSRF-TOKEN',
      
    }),

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),

    ApiModule,
     ServiceWorkerModule.register('ngsw-worker.js', {
       enabled: environment.production,
       // Register the ServiceWorker as soon as the app is stable
       // or after 30 seconds (whichever comes first).
       registrationStrategy: 'registerWhenStable:10000'
     }),
  ],
  bootstrap: [
    AppComponent
  ],
  providers: [
    LanguageService,
    FormPageService,
    UserService,
  ]
})
export class AppModule {
  constructor(
    iconRegistry: MatIconRegistry,
    langService: LanguageService,
    configService: GeneralConfigService,
    ) {
    iconRegistry.setDefaultFontSetClass('material-icons-outlined');
  }  
}
