import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { YesNoInputComponent } from './yes-no-input.component';
import { CommonMatImportsModule } from 'src/app/modules/app-imports/common-mat-imports.module';
import { CommonImportsModule } from 'src/app/modules/app-imports/common-imports.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    YesNoInputComponent
  ],
  imports: [
    CommonModule,

    FlexLayoutModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    
    CommonMatImportsModule
  ],
  exports: [
    YesNoInputComponent
  ]
})
export class YesNoInputModule { }
