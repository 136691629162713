import { ApplicationRef, ChangeDetectorRef, Injectable } from '@angular/core';
import { FormGroup, ValidatorFn, AbstractControl, FormArray } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class FormPageService {

  constructor(
    private change: ApplicationRef,
    private translate: TranslateService
  ) { }

  showError(form: FormGroup, key: string, error: string, groupError: boolean = false) : boolean | undefined {
		try {

			if (!groupError) {
				// return (form.get(key)?.touched || form.get(key)?.dirty) && form.get(key)?.hasError(error);
				return form.get(key)?.hasError(error);
			} else {

				if ((form.get(key)?.touched || form.get(key)?.dirty) && form.hasError(error)) {
					form.get(key)?.setErrors({"genericError": "genericError", ...form.get(key)?.errors})
          this.change.tick()
					return true
				} else {
					form.get(key)?.setErrors(null)
          this.change.tick()
					return false
				}

			}
			
		} catch (error) {
			// console.error(`FormControl: '${key}' wasn't found in FormGroup: ${JSON.stringify(form.value)}`, error)
			return undefined;
		}
	}

  getAllErrors(form: FormGroup | FormArray): { [key: string]: any; } | null {
    let hasError = false;
    const result = Object.keys(form.controls).reduce((acc, key) => {
        const control = form.get(key);
        const errors = (control instanceof FormGroup || control instanceof FormArray)
            ? this.getAllErrors(control)
            : control.errors;
        if (errors) {
            acc[key] = errors;
            hasError = true;
        }
        return acc;
    }, {} as { [key: string]: any; });
    return hasError ? result : null;
  }

  showErrors(form: FormGroup, key: string, errors: {[key: string]: string}) : string {
    let arr: string[] = []
    Object.entries(errors).forEach(
      ([type, error]) => {
        if (form.get(key).hasError(type)) {
          arr.push(
            this.translate.instant(error)
          )
        }
      }
    )
    if (form.hasError(key)) {
      form.get(key).setErrors(form.getError(key))
      arr.push(
        form.getError(key).join(', ')
      )
    }
    return arr.join('\n');
  }

	aShowError(form: FormGroup, key: string, error: string, groupError: boolean = false) : Promise<boolean | undefined> {
		return new Promise((resolve) => {
			resolve(this.showError(form, key, error, groupError))
		})
	}

	showApiError(form: FormGroup, key: string) {
		return this.showError(form, key, key, true)
	}

	getApiError(form: FormGroup, key: string) {
		return form.getError(key)
	}


	passwordsMatchValidator(): ValidatorFn {
		return (control: AbstractControl): { [key: string]: boolean } => {
			let res = false;
			if (control.get('password') && control.get('password_confirmation')) {
				res = control.get('password')?.value !== control.get('password_confirmation')?.value;
			}
			return { 'mismatch': res };
		}
  }
}
