import { AfterViewInit, ApplicationRef, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { interval, concat } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApiService } from './modules/api/api.service';
import { SwUpdate } from '@angular/service-worker';
import { AppSplashScreenService } from './services/splash-screen.service';
import { MatDialog } from '@angular/material/dialog';
import { UpdateDialogComponent } from './components/update-dialog/update-dialog.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
  title = 'DC-employee';
  updateDialogShowing: boolean = false;

  constructor(
    private spashScreen: AppSplashScreenService,
    private api: ApiService,
    private router: Router,
    private applicationRef: ApplicationRef,
    private swUpdate: SwUpdate,
    private dialogService: MatDialog,
  ) {

    const appIsStable$ = this.applicationRef.isStable.pipe(first(isStable => isStable === true));
    const everyFiveMinutes$ = interval(5 * 60 * 1000);
    const everySixHoursOnceAppIsStable$ = concat(appIsStable$, everyFiveMinutes$);
    
    console.log('ServiceWorker update enabled: ', this.swUpdate.isEnabled);
    if (!this.swUpdate.isEnabled) {
      return
    }

    everySixHoursOnceAppIsStable$.subscribe(() => this.swUpdate.checkForUpdate());
    if (this.swUpdate.isEnabled) {

      this.swUpdate.available.subscribe(() => {

        if (this.updateDialogShowing) {
          return;
        }

        this.updateDialogShowing = true;
        console.log('ServiceWorker update available: yes');
        
        this.dialogService.open(UpdateDialogComponent).beforeClosed().subscribe(response => {
          if (response === true) {
            this.swUpdate.activateUpdate().then(() => {
              window.location.reload();
            });
          }
        });

      });

    }

  }
  ngAfterViewInit(): void {
    // this.dialogService.open(UpdateDialogComponent).beforeClosed().subscribe(response => {
    //   if (response === true) {
    //     this.spashScreen.show()
    //     this.swUpdate.activateUpdate().then(() => {
    //       window.location.reload();
    //     });
    //   }
    // });
  }

  @HostListener('window:beforeunload')
  logout(e: BeforeUnloadEvent) {

    // DISABLE auto-logoff ON DEV ENVIRONMENTS
    if (!environment.production) {
      return null
    }
    
    this.api.auth.logout().subscribe()
    return e
  }



}
