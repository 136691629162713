import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CenteredPageComponent } from './centered-page.component';
import { CommonImportsModule } from 'src/app/modules/app-imports/common-imports.module';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { FlexLayoutModule } from '@angular/flex-layout';



@NgModule({
  declarations: [
    CenteredPageComponent
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    PerfectScrollbarModule
  ],
  exports: [
    CenteredPageComponent
  ]
})
export class CenteredPageModule { }
