import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApiService } from './api.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { RetryInterceptor } from './interceptors/retry/retry.interceptor';
import { ErrorInterceptor } from './interceptors/error/error.interceptor';

import { MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar'
import { RouterModule } from '@angular/router';
import { Error403Interceptor } from './interceptors/error/error-403.interceptor';
import { Error401Interceptor } from './interceptors/error/error-401.interceptor';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule,

    MatSnackBarModule,
  ],
  providers: [
    ApiService,
    { 
      provide: HTTP_INTERCEPTORS, 
      useClass: ErrorInterceptor, 
      multi: true
    },
    { 
      provide: HTTP_INTERCEPTORS, 
      useClass: RetryInterceptor, 
      multi: true
    },
  ]
})
export class ApiModule { }
