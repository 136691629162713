
<ng-content select="app-input-label"></ng-content>

<div class="input-wrapper" fxLayout="row" fxLayoutGap="16px">

	<button mat-button type="button" fxFlex="1 1 50%" class="dc-green" (click)="setValue(true)" [class.unchecked]="value === false">
		<mat-icon [class.gone]="value === true">circle</mat-icon>
		<mat-icon [class.gone]="value !== true">check_circle</mat-icon>
		{{"TERMS.YES" | translate }}
	</button>
	<button mat-button type="button" fxFlex="1 1 50%" class="dc-red" (click)="setValue(false)" [class.unchecked]="value === true">
		<mat-icon [class.gone]="value === false">circle</mat-icon>
		<mat-icon [class.gone]="value !== false">cancel</mat-icon>
		{{"TERMS.NO" | translate }}
	</button>

</div>

<div class="errors" *ngIf="showErrors">
	<ng-content select="mat-error"></ng-content>
</div>