import { Department } from "./config.types";

export interface Interval {
	left: number,
	total: number
}

export interface OverviewAction {
	allDone: boolean
	id: number
	name: string
	other: number
	today: number
}

export interface OverviewSummary {
	today: Interval,
	other: Interval
}


export interface ActionOverview {
	today: ActionInterval
	other: ActionInterval
}

export interface ActionInterval {
	total: number
	left: number
	tasks: Task[]
}

export class Task {
	is_simple_checkable: boolean
	department: Department[]
	id: number
	interval: string
	name: string
	task_done: boolean
	task_done_properly: boolean
	image: string
	
	checklistItems: ChecklistItem[]
	checklist_must_be_filled: boolean

	notes: string

	scheduled_for: number

	has_instructions: boolean
	instructions?: string

	get isCheckable(): boolean {
    return this.is_simple_checkable && !this.task_done_properly
    // return !task.task_done_properly
  }

	get appIndicatorValue() {
    if (this.isCheckable) return "checkable";
    if (this.task_done_properly != null) return this.task_done_properly

    return "checkable_disabled";
  }
}

export class CleaninglistTask extends Task {
	must_be_cleaned: boolean
	must_be_disinfected: boolean
	was_cleaned: boolean
	was_disinfected: boolean
}

export class RegistrationStorageTask extends Task {
	cause: string
	solution: string
	storage_in_use: boolean
	temperature: number
	temperature_requirement: {
		name: string,
		code: string,
		character: string
	}
	standardAnswers: [
		{
			name: string,
			notes: string,
			id: number
		}
	]
}

export interface ChecklistItem {
	id: number,
	name: string
	notes: string
	sequence: number,
	response: boolean | null
}