
<div class="scroll-wrapper" fxLayout="column" #scrollWrapper focusable>

	<div class="scroll-content" fxFlex="1 1 auto">

		<perfect-scrollbar [config]="{ suppressScrollX: true }"
		(psScrollY)="scrollWrapper.focus()">

			<div class="toolbar">
				<ng-content select="[page-toolbar]"></ng-content>
			</div>

			<div class="centered-page-wrapper width-container" [style.padding.px]="pagePadding">
			
				<ng-content></ng-content>
			
			</div>

		</perfect-scrollbar>

	</div>


	<div class="page-sticky-footer" fxFlex="0 0 auto">
		<ng-content select="app-button-bar"></ng-content>
	</div>

</div>
