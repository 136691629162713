

<div class="heading-wrapper">

	<div class="content normal" fxLayout="row" fxFlex="1 0 100%" fxLayoutGap="8px" fxLayoutAlign="start center">
		<ng-content></ng-content>
	</div>

	<div class="content items" fxLayout="row" fxFlex="1 0 100%">
		<ng-content select="[heading-item]"></ng-content>
	</div>

</div>

<mat-divider></mat-divider>
