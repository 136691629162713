

<div class="page-header white mat-elevation-z4 p-16" fxLayout="column wrap" fxLayoutAlign="center center">

	<div class="width-container page-title">

		<ng-content select="[page-title]"></ng-content>

	</div>

	<div class="width-container page-filter">

		<ng-content select="[page-filter]"></ng-content>

	</div>

	<div class="width-container page-subheading">

		<ng-content select="[page-subheading]"></ng-content>

	</div>

</div>

<!-- <mat-toolbar class="white mat-elevation-z4">
	<mat-toolbar-row class="width-container page-title">
	</mat-toolbar-row>
	<mat-toolbar-row class="width-container page-filter">
	</mat-toolbar-row>
	<mat-toolbar-row class="width-container subheading">
	</mat-toolbar-row>
</mat-toolbar> -->