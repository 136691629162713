import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GeneralConfigService } from 'src/app/modules/config/general/general-config.service';
import { Department } from 'src/app/types/config.types';
import { DepartmentSelectService } from './department-select.service';

@Component({
  selector: 'app-department-select',
  templateUrl: './department-select.component.html',
  styleUrls: ['./department-select.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DepartmentSelectComponent implements OnInit, OnDestroy {

  private _unsubAll: Subject<any>;
  constructor(
    //TODO: Generate Config service and draw departments from this
    private config: GeneralConfigService,
    private service: DepartmentSelectService
  ) {
    this._unsubAll = new Subject<any>()
  }
  ngOnDestroy(): void {
    this._unsubAll.next()
    this._unsubAll.complete()
  }


  public get prevSelection() {
    return this.service.selected
  }
  public set prevSelection(value: number | string) {
    this.service.selected = value
  }


  @Output('valueChange') valueChange: EventEmitter<any> = new EventEmitter()

  form: FormGroup = new FormGroup({
    "department": new FormControl(this.prevSelection)
  })


  departments: Department[] = []

  valueIsChanged(event: Event) {
    let select: any = event.target
    this.prevSelection = select.value

    this.valueChange.emit(select.value)
  }

  ngOnInit(): void {
    this.service.departments.pipe(
      takeUntil(this._unsubAll)
    ).subscribe(
      (res) => {
        this.departments = res
      }
    )
  }

}


export const DEPARTMENT_SELECT_SELECTION: string = 'department_select_selection';
