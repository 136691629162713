import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-centered-page',
  templateUrl: './centered-page.component.html',
  styleUrls: ['./centered-page.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CenteredPageComponent implements OnInit {

  constructor() { }

  @Input('page-padding') pagePadding: number = 16

  ngOnInit(): void {
  }

}
