import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { MatSnackBarDefaults } from 'src/app/modules/app-imports/common-mat-imports.module';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    private sb: MatSnackBar,
    private router: Router
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {

        if (error.status === 401) {
          return next.handle(request)
        }

        if (error.error && error.error?.message) {
          this.sb.open(error.error.message, "", this.sbOptions())
          return throwError(error)
        }

        switch (error.status) {

          case 401:
            // This will be handled in the 401error.interceptor.ts
            break;
          case 403:
            console.log("Oh no, a 403!");
            this.router.navigate(['/logout'])
            break;
          case 404:
            this.sb.open("Oops! Something went wrong and we couldn't find what you were looking for (404)", "", this.sbOptions());
            break;
          case 422:
            this.sb.open("The data you submitted was faulty", "", this.sbOptions());
            break;

          case 500:
            this.sb.open("Something went wrong on our servers. Please try again later (500)", "", this.sbOptions());
            break;

          case 504:
            // App is offline, go to offline page
            this.router.navigate(['device-offline'])
            break;

          // TOKEN SPECIFIC
          case 419:
            this.sb.open("Something went wrong with your authentication (419)", "", this.sbOptions());
            break;

          default:
            this.sb.open(`Something unexpected went wrong, please try again (${error.status})`, "", this.sbOptions());

        }



        return throwError(error)
      })
    )
  }



  sbOptions() {
    return {...MatSnackBarDefaults, panelClass: "red-800" }
  }
}
