import { EventEmitter, Injectable, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { filter, first, shareReplay, take, takeUntil } from 'rxjs/operators';
import { Company } from 'src/app/types/config.types';
import { ApiService } from '../../api/api.service';
import { AppMainModule } from '../../app-main/app-main.module';
import { UserService } from '../../user/user.service';

@Injectable({
  providedIn: 'root',
})
export class GeneralConfigService implements OnDestroy {

  private _unsubAll: Subject<any>;
  constructor(
    private api: ApiService,
    private userService: UserService
  ) {

    this._unsubAll = new Subject();

    this.userService.user.pipe(
      takeUntil(this._unsubAll)
    ).subscribe(
      (user) => {
        if (user) {
          this.userLoggedIn()
        }
      }
    )

    this.readFromLocalStorage()
    
    this.setStyles()
  }
  ngOnDestroy(): void {
    this._unsubAll.next()
    this._unsubAll.complete()
  }

  public currentUserIsFromDifferentCompany: boolean = false;

  userLoggedIn() {
    this.updateConfig()
  }

  public companyDetails: Subject<Company> = new ReplaySubject<Company>(1);

  private _storage: Storage = localStorage;

  getConfig(): AppConfig {
    return JSON.parse(this._storage.getItem(APP_CONFIG_STORAGE_KEY))
  }

  deleteConfig() {
    this._storage.removeItem(APP_CONFIG_STORAGE_KEY)
    this.companyDetails = new ReplaySubject<Company>(1)
    this.setStyles()
  }

  get deviceId(): string {
    return this.getConfig()?.device?.device_id
  }
  set device(device: DeviceRegistration) {
    this._storage.setItem(APP_CONFIG_STORAGE_KEY, JSON.stringify({...this.getConfig(), device: device}))
  }
  get hasDeviceId(): boolean {
    return this.deviceId !== undefined
  }



  get company(): Company {
    return this.getConfig()?.company
  }
  set company(company: Company) {
    this.companyDetails.next(company)
    this._storage.setItem(APP_CONFIG_STORAGE_KEY, JSON.stringify({...this.getConfig(), company: company}))
  }
  public prevConfig: AppConfig = this.getConfig()
  revertCompany() {
    this.company = this.prevConfig?.company
    this.setStyles()
  }
  

  public updateConfig(): Promise<void> {

    return this.api.config.company.get().toPromise().then(
      (res) => {
        this.currentUserIsFromDifferentCompany = (JSON.stringify(this.prevConfig?.company) === JSON.stringify(res.data))
        this.company = res.data
        this.setStyles()
      }
    )
  }

  readFromLocalStorage() {
    let stored = this.getConfig()
    if (!stored) {
      return;
    }

    this.companyDetails.next(stored.company)
    this.device = stored.device
  }

  // updateCompanyDetails() : Promise<Company> {
  //   return new Promise(
  //     (resolve, reject) => {
  //       if (this._companyDetails !== undefined) {
  //         resolve(this._companyDetails)
  //         return
  //       }

  //       this.api.config.company.get().subscribe(
  //         (res) => {
  //           this._companyDetails = res.data
  //           this.companyDetails.next(res.data)
  //           resolve(res.data)
  //         },
  //         (err) => {
  //           reject(err)
  //         }
  //       )
  //     }
  //   )
  // }


  // isSingleBusiness() : Promise<boolean> {
  //   return new Promise<boolean>(
  //     (resolve, reject) => {

  //       this.companyDetails.pipe(
  //         filter((details) => details !== undefined),
  //         take(1)
  //       ).subscribe(
  //         (res) => {
  //           if (!res) {
  //             resolve(undefined)
  //             return undefined;
  //           }
  //           if (res.business_type.id === 3) {
  //             // 3 is Single business
  //             resolve(true)
  //             return true
  //           } else {
  //             resolve(false)
  //             return false
  //           }
  //         },
  //         (err) => {
  //           reject(err)
  //           return false
  //         }
  //       )

  //     }
  //   )
  // }


  setStyles() {

    document.querySelector('style#companyStyle')?.remove()

    if (!this.company)
      return;

    var theme = document.createElement('style'),
        head = document.head || document.getElementsByTagName('head')[0]

    theme.id = "companyStyle"

    head.appendChild(theme);

    let styles = []

    if (this.company.color_menu_bar)
      styles.push(`--company-color-menu-bar: ${this.company.color_menu_bar}`)
    
    if (this.company.color_primary_button)
      styles.push(`--company-color-primary-button: ${this.company.color_primary_button}`)

    if (this.company.color_secondary_button)
      styles.push(`--company-color-secondary-button: ${this.company.color_secondary_button}`)

    document.querySelector('head meta[name="theme-color"]').setAttribute("content", this.company.color_menu_bar)

    theme.type = 'text/css';
    theme.appendChild(document.createTextNode(`:root { ${ styles.join('; ') } } `));

  }
}


export const APP_CONFIG_STORAGE_KEY: string = "app_config";

export type AppConfig = {

  company: Company,
  device: DeviceRegistration,

}
export type DeviceRegistration = {
  device_id: string,
  client_id: number,
  description: string,
}
