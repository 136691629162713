import { NgModule } from "@angular/core";

import { MatButtonModule } from "@angular/material/button"
import { MatIconModule } from "@angular/material/icon"
import { MatCardModule } from "@angular/material/card"
import { MatToolbarModule } from "@angular/material/toolbar"
import { MatDividerModule } from "@angular/material/divider"
import { MatRippleModule } from '@angular/material/core';
import { MatFormFieldDefaultOptions, MatFormFieldModule, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSnackBarConfig, MAT_SNACK_BAR_DEFAULT_OPTIONS } from "@angular/material/snack-bar";





export const MatSnackBarDefaults: MatSnackBarConfig = {
	duration: 4000,
	verticalPosition: 'bottom',
	panelClass: "dc-green"
}
export const MatFormFieldDefaults: MatFormFieldDefaultOptions = {
	appearance: 'outline'
}


@NgModule({
  declarations: [],
  imports: [],
  exports: [
		MatButtonModule,
		MatIconModule,
		MatCardModule,
		MatToolbarModule,
		MatDividerModule,
		MatRippleModule,
		MatFormFieldModule,
		MatSelectModule,
		MatCheckboxModule,
		MatInputModule,
		MatProgressSpinnerModule
  ],

	providers: [
		{
			provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: MatFormFieldDefaults
		},
		{
			provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: MatSnackBarDefaults
		}
	]
})
export class CommonMatImportsModule { }

