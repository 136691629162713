

<form [formGroup]="form">

	<mat-form-field>

		<select matNativeControl #select (change)="valueIsChanged($event)" formControlName="department">
			<option [value]="''" class="placeholder-item">{{"DEPARTMENT.SELECT.PLACEHOLDER" | translate}}</option>
			<option *ngFor="let department of departments" [value]="department.id">{{department.name}}</option>
		</select>

	</mat-form-field>
	
</form>