
<div class="indicator-tap-area"


[class.tappable]="isCheckable">
	<div class="indicator-wrapper" [class]="color"

		[class.dc-green]="isBoolean && value === true"
		[class.dc-red]="isBoolean && value === false"
		[class.orange]="isWarn || isUnfinished"
		[class.white]="isMinus || isQuestion || isCheckable || isCheckableDisabled"

		[style.border]="isCheckable ? '2px solid black' : isCheckableDisabled ? '2px solid lightgrey' : ''"

		>

		<mat-icon *ngIf="isBoolean">{{ value === true ? 'check' : 'close'}}</mat-icon>
		<mat-icon *ngIf="isUnfinished || isMinus">remove</mat-icon>
		<!-- <mat-icon *ngIf="isQuestion">question_mark</mat-icon> -->
		<mat-icon *ngIf="isWarn">priority_high</mat-icon>


		<ng-content *ngIf="value === undefined"></ng-content>

	</div>
</div>

