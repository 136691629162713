import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DepartmentSelectComponent } from './department-select.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MatInputModule } from '@angular/material/input';



@NgModule({
  declarations: [
    DepartmentSelectComponent
  ],
  imports: [
    CommonModule,

    ReactiveFormsModule,
    TranslateModule,

    MatFormFieldModule,
    MatInputModule,
    MatSelectModule
  ],
  exports: [
    DepartmentSelectComponent
  ]
})
export class DepartmentSelectModule { }
