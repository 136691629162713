import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-status-indicator',
  templateUrl: './status-indicator.component.html',
  styleUrls: ['./status-indicator.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StatusIndicatorComponent implements OnInit {

  @Input('value') value: boolean | number | 'unfinished' | 'warn' | 'minus' | 'question' | 'checkable' | 'checkable_disabled';
  @Input('color') color: string = 'primary'

  constructor() { }

  ngOnInit(): void {
  }

  get isBoolean() {
    return typeof this.value === 'boolean'
  }

  get isUnfinished() {
    return this.value === 'unfinished'
  }

  get isCheckable() {
    return this.value === 'checkable'
  }
  
  get isCheckableDisabled() {
    return this.value === 'checkable_disabled'
  }

  get isMinus() {
    return this.value === 'minus'
  }

  get isQuestion() {
    return this.value === 'question'
  }

  get isWarn() {
    return this.value === 'warn'
  }

}
