import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { Language } from 'src/app/types/config.types';
import { ApiService } from '../../api/api.service';


import "./locale/en.js";
import "./locale/nl.js";


export let LANG_KEY: string = "conf_language";

@Injectable({
  providedIn: 'root',
})
export class LanguageService {

  constructor(
    private translate: TranslateService,
    private api: ApiService
  ) {

    this.api.config.languages.get().subscribe(
      (res) => {
        this.languages = res.data
        this.setLang(this.getStoredLang())
      },
      (err) => {
        // Set stored language, or default to english
        this.setLang(this.getStoredLang())
      }
    )
  }

  private currentLang: string = undefined
  public languages: Language[] = []

  private _changesSub: Subject<Language> = new Subject<Language>()

  setLang(iso: string) {

    if (iso === this.currentLang) {
      console.info("Lang already set")
      return
    }

    console.info("Setlang: ", iso)

    this.currentLang = iso
    localStorage.setItem(LANG_KEY, this.currentLang.toString())
    this.translate.use(iso)

    moment.locale(iso)

    this._changesSub.next(this.languages.find(l => l.iso === iso))
  }

  getLangObservable() {
    return this._changesSub.asObservable()
  }

  getCurrentLang(): string {
    return this.currentLang
  }

  getStoredLang(): string {
    let storedIso = localStorage.getItem(LANG_KEY)

    if (!storedIso?.match("^[a-z]{2}$")) {
      this.setLang('nl')
      return 'nl'
    }

    if (storedIso) {
      return storedIso
    } else {
      return 'nl' // Always defaults to Dutch if not stored yet
    }
  }
 }
