

<div class="list-item-wrapper" mat-ripple>

	<div class="list-item-base" fxLayout="row">

		<div class="list-item-prefix"
			fxLayout="column"
			fxLayoutAlign="center start">
	
			<ng-content select="[item-prefix]"></ng-content>
	
		</div>

		<div class="list-item-prefix-fixed"
			fxLayout="column"
			fxLayoutAlign="center start">
	
			<ng-content select="[item-prefix-fixed]"></ng-content>
	
		</div>
	
		<div class="list-item-content" 
			fxFlex="1 1 auto"
			fxLayout="column"
			fxLayoutAlign="center start">
	
			<ng-content select="[item-content]"></ng-content>
	
		</div>
	
		<div class="list-item-suffix" [class]="suffixClasses"
			fxLayout="row" 
			fxLayoutAlign="center center">
	
			<ng-content select="[item-suffix]"></ng-content>
	
			<mat-icon class="list-item-click-indicator" *ngIf="clickIndicator">chevron_right</mat-icon>
	
		</div>
	
	</div>
	
	<mat-divider class="my-0"></mat-divider>


</div>


