import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthstateGuard } from './guards/authstate.guard';
import { LoggedInGuard } from './guards/logged-in.guard';
import { IsOnlineGuard } from './modules/offline/is-online.guard';
import { UserResolver } from './modules/user/user.resolver';

const routes: Routes = [
  {
    path      : '',
    pathMatch : 'full',
    redirectTo: 'pin',
  },
  {
    path: '',
    canActivateChild: [
      IsOnlineGuard
    ],
    children: [
      {
        path: '',
        loadChildren: () => import('./modules/authentication/authentication.module').then(m => m.AuthenticationModule),
        canActivate: [LoggedInGuard]
      },
      {
        path: '',
        loadChildren: () => import('./modules/app-main/app-main.module').then(m => m.AppMainModule),
        canActivate: [AuthstateGuard],
      },
    ],
  },
  {
    path: '',
    loadChildren: () => import('./modules/offline/offline.module').then(m => m.OfflineModule),
  },
  {
    path      : '**',
    redirectTo: 'pin',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false })],
  exports: [RouterModule],
  // providers: [UserResolver]
})
export class AppRoutingModule { }
