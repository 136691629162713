import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UpdateDialogComponent } from './update-dialog.component';
import { CommonMatImportsModule } from 'src/app/modules/app-imports/common-mat-imports.module';
import { CommonImportsModule } from 'src/app/modules/app-imports/common-imports.module';
import { MatDialogModule } from '@angular/material/dialog';



@NgModule({
  declarations: [
    UpdateDialogComponent
  ],
  imports: [
    CommonModule,
    CommonImportsModule,
    CommonMatImportsModule,

    MatDialogModule
  ],
  exports: [
    UpdateDialogComponent
  ]
})
export class UpdateDialogModule { }
