import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListPageHeaderComponent } from './list-page-header.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { CommonImportsModule } from 'src/app/modules/app-imports/common-imports.module';
import { FlexLayoutModule } from '@angular/flex-layout';



@NgModule({
  declarations: [
    ListPageHeaderComponent
  ],
  imports: [
    CommonModule,

    FlexLayoutModule,
    
    MatToolbarModule
  ],
  exports: [
    ListPageHeaderComponent
  ]
})
export class ListPageHeaderModule { }
