import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-list-item',
  templateUrl: './list-item.component.html',
  styleUrls: ['./list-item.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ListItemComponent implements OnInit {

  constructor() { }

  @Input('click-indicator') clickIndicator: boolean = true;
  @Input('suffix-classes') suffixClasses: string = '';

  ngOnInit(): void {
  }

}
