import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from 'src/app/modules/api/api.service';
import { LanguageService } from 'src/app/modules/config/language/language.service';
import { Department } from 'src/app/types/config.types';


@Injectable({
  providedIn: 'root'
})
export class DepartmentSelectService {

  constructor(
    private api: ApiService,
    private language: LanguageService
  ) {
    this.getDepartments()

    this.language.getLangObservable().subscribe(
      (lang) => {
        this.getDepartments()
      }
    )
  }

  private _selected: number | string = ''
  
  get selected(): number | string {
    return this._selected
  }

  set selected(value: number | string) {
    this._selected = value
  }

  public departments: BehaviorSubject<Department[]> = new BehaviorSubject<Department[]>([])

  getDepartments() {
    this.api.employee.departments().subscribe(
      (res) => {
        this.departments.next(res.data)
      }
    )
  }
  
}
