import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListItemComponent } from './list-item.component';
import { CommonMatImportsModule } from 'src/app/modules/app-imports/common-mat-imports.module';
import { CommonImportsModule } from 'src/app/modules/app-imports/common-imports.module';
import { FlexLayoutModule } from '@angular/flex-layout';



@NgModule({
  declarations: [
    ListItemComponent
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,

    CommonMatImportsModule
  ],
  exports: [
    ListItemComponent
  ]
})
export class ListItemModule { }
