import { HttpClient, HttpXsrfTokenExtractor } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { Action, Company, Language, Location, Role } from 'src/app/types/config.types';
import { ActionOverview, CleaninglistTask, OverviewAction, RegistrationStorageTask, Task } from 'src/app/types/overview.types';
import { User } from 'src/app/types/user.types';
import { environment } from 'src/environments/environment';
import { DataResponse, LoginResponse, MessageResponse, OverviewResponse } from './api-response.types';

@Injectable()
export class ApiService implements OnDestroy {

  private base: string = environment.api.url
  private v: string = environment.api.version


  private _unsubAll: Subject<any>;

  constructor(
    private http: HttpClient,
    private xsrfExtractor: HttpXsrfTokenExtractor
  ) {

    this._unsubAll = new Subject();

    // Check if CSRF token is there and get one if not
    this.initCsrfToken()

  }

  get options() {
    return {
      headers: {
        'ngsw-bypass': 'true'
      },
      withCredentials: true,
    }
  }

  initCsrfToken() {
    if (this.xsrfExtractor.getToken() === null) {
      // token is not set, hit get endpoint for token
      this.getCsrfToken().pipe(
        takeUntil(this._unsubAll)
      ).subscribe()
    } else {
    }
  }
  getCsrfToken() {
    return this.http.get(`${this.base}/auth/csrf-cookie`, this.options)
  }


  //#region SETTINGS

  //#endregion

  public manager = {

    locations: {
      get: () : Observable<DataResponse<Location[]>> => {
        return this.http.get<DataResponse<Location[]>>(`${this.base}/${this.v}/manager/company/locations`, this.options)
      },
    },

    device: {

      postDevice: (data: any) : Observable<DataResponse<any>> => {
        return this.http.post<DataResponse<any>>(`${this.base}/${this.v}/manager/company/devices`, this.processData(data), this.options)
      },

      deleteDevice: (deviceId: string) : Observable<DataResponse<any>> => {
        return this.http.delete<DataResponse<any>>(`${this.base}/${this.v}/manager/company/devices/${deviceId}`, this.options)
      },

      updateDevice: (deviceId: string, data: any) : Observable<DataResponse<any>> => {
        return this.http.post<DataResponse<any>>(`${this.base}/${this.v}/manager/company/devices/${deviceId}`, this.processDataPut(data), this.options)
      },

      // getDevice: (deviceId: string) : Observable<DataResponse<any>> => {
      //   return this.http.get<DataResponse<any>>(`${this.base}/${this.v}/manager/company/locations/${locationId}/register-device/${deviceId}`, this.options)
      // },

    }

  }

  public employee = {

    departments: () : Observable<DataResponse<any>> => {
      return this.http.get<DataResponse<any>>(`${this.base}/${this.v}/employee/departments`, this.options)
    },
  }

  public config = {

    company: {

      get: (): Observable<DataResponse<Company>> => {
        return this.http.get<DataResponse<Company>>(`${this.base}/${this.v}/settings/company/details`, this.options)
      },

    },

    languages: {

      get: (): Observable<DataResponse<Language[]>> => {
        return this.http.get<DataResponse<Language[]>>(`${this.base}/${this.v}/settings/languages`, this.options)
      },

    },

    roles: {

      get: (): Observable<DataResponse<Role[]>> => {
        return this.http.get<DataResponse<Role[]>>(`${this.base}/${this.v}/settings/roles`, this.options)
      },

    },

    actions: {

      get: (): Observable<DataResponse<Action[]>> => {
        return this.http.get<DataResponse<Action[]>>(`${this.base}/${this.v}/settings/actions`, this.options)
      },

    }

  }


  public settings = {

    languages: () : Observable<DataResponse<any>> => {
      return this.http.get<DataResponse<any>>(`${this.base}/${this.v}/settings/languages`, this.options)
    },

    // languagesPost: (formData: any) : Observable<LoginResponse> => {
    //   return this.http.post<LoginResponse>(`${this.base}/${this.v}/settings/languages`, this.processData(formData), this.options)
    // },

    countries: () : Observable<DataResponse<any>> => {
      return this.http.get<DataResponse<any>>(`${this.base}/${this.v}/settings/countries`, this.options)
    },

    modules: () : Observable<DataResponse<any>> => {
      return this.http.get<DataResponse<any>>(`${this.base}/${this.v}/settings/modules`, this.options)
    },

    roles: () : Observable<DataResponse<any>> => {
      return this.http.get<DataResponse<any>>(`${this.base}/${this.v}/settings/roles`, this.options)
    },

    actions: () : Observable<DataResponse<any>> => {
      return this.http.get<DataResponse<any>>(`${this.base}/${this.v}/settings/actions`, this.options)
    },

    businessTypes: () : Observable<DataResponse<any>> => {
      return this.http.get<DataResponse<any>>(`${this.base}/${this.v}/settings/business-types`, this.options)
    },
  }

  public auth = {

    login: (formData: any): Observable<LoginResponse> => {
      return this.http.post<LoginResponse>(`${this.base}/auth/login`, this.processData(formData), this.options)
    },

    loginPincode: (formData: any): Observable<DataResponse<User>> => {
      return this.http.post<DataResponse<User>>(`${this.base}/auth/login-pincode`, this.processData(formData), this.options)
    },

    isAuthenticated: (): Observable<boolean> => {
      return this.http.get<boolean>(`${this.base}/auth/is-authenticated`, this.options)
    },
  
    logout: (): Observable<{}> => {
      return this.http.get<{}>(`${this.base}/auth/logout`, this.options)
    },
  
    forgotPassword: (formData: any): Observable<MessageResponse> => {
      return this.http.post<MessageResponse>(`${this.base}/auth/forgot-password`, this.processData(formData), this.options)
    },
  
    resetPassword: (formData: any): Observable<MessageResponse> => {
      return this.http.post<MessageResponse>(`${this.base}/auth/reset-password`, this.processData(formData), this.options)
    },
  
    invitation: (formData: any): Observable<MessageResponse> => {
      return this.http.post<MessageResponse>(`${this.base}/auth/invitation`, this.processData(formData), this.options)
    },
  
    changePassword: (formData: any): Observable<MessageResponse> => {
      return this.http.post<MessageResponse>(`${this.base}/auth/change-password`, this.processData(formData), this.options)
    },

    twoFactor: {

      challenge: (formData: any): Observable<MessageResponse> => {
        return this.http.post<MessageResponse>(`${this.base}/auth/two-factor-challenge`, this.processData(formData), this.options)
      },

      enable: {

        enable: (): Observable<MessageResponse> => {
          return this.http.get<MessageResponse>(`${this.base}/auth/two-factor-authentication`, this.options)
        },
        
        qr: (): Observable<MessageResponse> => {
          return this.http.get<MessageResponse>(`${this.base}/auth/two-factor-qr-code`, this.options)
        },
      
        confirm: (formData: any): Observable<MessageResponse> => {
          return this.http.post<MessageResponse>(`${this.base}/auth/two-factor-authentication`, this.processData(formData), this.options)
        },

        cancel: (): Observable<MessageResponse> => {
          return this.http.get<MessageResponse>(`${this.base}/auth/two-factor-authentication-cancel`, this.options)
        }

      },
    
      codes: (): Observable<DataResponse<string[]>> => {
        return this.http.get<DataResponse<string[]>>(`${this.base}/auth/two-factor-recovery-codes`, this.options)
      },
    
      regenerateCodes: (formData: any): Observable<MessageResponse> => {
        return this.http.post<MessageResponse>(`${this.base}/auth/two-factor-recovery-codes`, this.processData(formData), this.options)
      },
    
      disable: (): Observable<MessageResponse> => {
        return this.http.post<MessageResponse>(`${this.base}/auth/two-factor-authentication-disable`, this.options)
      }

    },

    profile: (): Observable<DataResponse<User>> => {
      return this.http.get<DataResponse<User>>(`${this.base}/auth/profile`, this.options)
    },
  
    updateProfile: (formData: any): Observable<DataResponse<User>> => {
      return this.http.post<DataResponse<User>>(`${this.base}/auth/profile`, this.processData(formData), this.options)
    }
  }

  public company = {

    general: () : Observable<DataResponse<any>> => {
      return this.http.get<DataResponse<any>>(`${this.base}/${this.v}/company/general`, this.options)
    },

    updateGeneral: (formData: any) : Observable<MessageResponse> => {
      return this.http.post<MessageResponse>(`${this.base}/${this.v}/company/genenral`, this.processData(formData), this.options)
    },

    locations: () : Observable<DataResponse<any>> => {
      return this.http.get<DataResponse<any>>(`${this.base}/${this.v}/company/locations`, this.options)
    },

    location: (id: number) : Observable<DataResponse<any>> => {
      return this.http.get<DataResponse<any>>(`${this.base}/${this.v}/company/locations/${id}`, this.options)
    },

  }

  public haccp = {

    overview: {

      get: (department_id: string | number = "") : Observable<OverviewResponse<OverviewAction[]>> => {
        return this.http.get<OverviewResponse<OverviewAction[]>>(`${this.base}/${this.v}/employee/overview`, {...this.options, params: {department_id: department_id} })
      },

    },

    cleaninglist: {

      overview: (department_id: string | number = "") : Observable<DataResponse<ActionOverview>> => {
        return this.http.get<DataResponse<ActionOverview>>(`${this.base}/${this.v}/employee/cleaninglist/overview`, {...this.options, params: {department_id: department_id} })
        .pipe(
          map(response => {
            response.data.today.tasks = response.data.today.tasks.map((task) => Object.assign(new Task(), task));
            response.data.other.tasks = response.data.other.tasks.map((task) => Object.assign(new Task(), task));

            return response;
          }),
        );
      },

      task: {

        get: (id: number) : Observable<DataResponse<CleaninglistTask>> => {
          return this.http.get<DataResponse<CleaninglistTask>>(`${this.base}/${this.v}/employee/cleaninglist/${id}`, this.options )
        },

        put: (formData: any, id: number) : Observable<MessageResponse> => {
          return this.http.post<MessageResponse>(`${this.base}/${this.v}/employee/cleaninglist/${id}`, this.processData({...formData, _method: "PUT"}), this.options)
        },

      }

    },

    actionlist: {

      overview: (department_id: string | number = "") : Observable<DataResponse<ActionOverview>> => {
        return this.http.get<DataResponse<ActionOverview>>(`${this.base}/${this.v}/employee/actionlist/overview`, {...this.options, params: {department_id: department_id} })
          .pipe(
            map(response => {
              response.data.today.tasks = response.data.today.tasks.map((task) => Object.assign(new Task(), task));
              response.data.other.tasks = response.data.other.tasks.map((task) => Object.assign(new Task(), task));

              return response;
            }),
          );
      },

      task: {

        get: (id: number) : Observable<DataResponse<Task>> => {
          return this.http.get<DataResponse<Task>>(`${this.base}/${this.v}/employee/actionlist/${id}`, this.options )
        },

        put: (formData: any, id: number) : Observable<MessageResponse> => {
          return this.http.post<MessageResponse>(`${this.base}/${this.v}/employee/actionlist/${id}`, this.processData({...formData, _method: "PUT"}), this.options)
        },

      }

    },

    registrationStorage: {

      overview: (department_id: string | number = "") : Observable<DataResponse<ActionOverview>> => {
        return this.http.get<DataResponse<ActionOverview>>(`${this.base}/${this.v}/employee/registration-storage/overview`, {...this.options, params: {department_id: department_id} })
        .pipe(
          map(response => {
            response.data.today.tasks = response.data.today.tasks.map((task) => Object.assign(new Task(), task));
            response.data.other.tasks = response.data.other.tasks.map((task) => Object.assign(new Task(), task));

            return response;
          }),
        );
      },

      task: {

        get: (id: number) : Observable<DataResponse<RegistrationStorageTask>> => {
          return this.http.get<DataResponse<RegistrationStorageTask>>(`${this.base}/${this.v}/employee/registration-storage/${id}`, this.options )
        },

        put: (formData: any, id: number) : Observable<MessageResponse> => {
          return this.http.post<MessageResponse>(`${this.base}/${this.v}/employee/registration-storage/${id}`, this.processData({...formData, _method: "PUT"}), this.options)
        },

      }

    }

  }


  processData(formData: any): FormData | null {
		let postData = new FormData();

		if (typeof formData !== 'undefined') {
			if (formData !== null) {
				Object.keys(formData).forEach(key => {
					if (Array.isArray(formData[key])) {
						for (let i = 0; i < formData[key].length; i++) {
							if (formData[key][i] !== false && formData[key][i] !== 'undefined') {
								postData.append(key + '[]', formData[key][i]);
							}
						}
					} else if (formData[key] instanceof Object) {
						Object.keys(formData[key]).forEach(key2 => {
							if (typeof key2 !== 'undefined') {
								if (formData[key][key2] !== false && formData[key][key2] !== 'undefined') {
									if (key2.indexOf('[') !== -1) {
										postData.append(key + key2, formData[key][key2]);
									} else {
										postData.append(key + '[' + key2 + ']', formData[key][key2]);
									}
								}
							} else {
								postData.append(key, formData[key]);
							}
						});
					} else {
						postData.append(key, formData[key]);
					}
				});

				return postData;
			} else {
				return null;
			}
		} else {
			return null;
		}
  }

  processDataPut(formData: any) {
    return this.processData({...formData, _method: "PUT"})
  }

  ngOnDestroy(): void {
    this._unsubAll.next()
    this._unsubAll.complete();
  }
}