import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { ApiService } from '../modules/api/api.service';
import { UserService } from '../modules/user/user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthstateGuard implements CanActivate {

  constructor(
    private api: ApiService,
    private router: Router
  ) { }
  
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.isLoggedInActivation(state);
  }
  canActivateChild(
    childstate: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.isLoggedInActivation(state);
  }
  

  isLoggedInActivation(route: RouterStateSnapshot): Promise<boolean | UrlTree> | boolean {

    // if (route.url.indexOf('logout') >= 0) {
    //   console.log("logging off...")
    //   return true;
    // }

    return new Promise(
      (resolve, reject) => {

        this.api.auth.isAuthenticated().subscribe(
          (res) => {
            if (res === true) {
              resolve(true)
            } else {
              // User is NOT logged in, so go to login
              resolve(this.router.createUrlTree(['pin']))
            }
          },
          (err) => {
            resolve(this.router.createUrlTree(['pin']))
          }
        )
        
      }
      
    )

  }

}
