import { NgModule } from '@angular/core';

import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { CenteredPageModule } from 'src/app/components/centered-page/centered-page.module';
import { DepartmentSelectModule } from 'src/app/components/department-select/department-select.module';
import { InputLabelModule } from 'src/app/components/input-label/input-label.module';
import { ListHeadingModule } from 'src/app/components/list-heading/list-heading.module';
import { ListItemModule } from 'src/app/components/list-item/list-item.module';
import { ListPageHeaderModule } from 'src/app/components/list-page-header/list-page-header.module';
import { StatusIndicatorModule } from 'src/app/components/status-indicator/status-indicator.module';
import { YesNoInputModule } from 'src/app/components/yes-no-input/yes-no-input.module';



@NgModule({
  declarations: [],
  imports: [
    NgxDatatableModule.forRoot({
      messages: {
        emptyMessage: 'Geen data om te laten zien.',
        totalMessage: 'in totaal',
        selectedMessage: 'geselecteerd'
      }
    }),
    TranslateModule,


    FlexLayoutModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgxDatatableModule,
    TranslateModule,
    CenteredPageModule,
    InputLabelModule,
    ListItemModule,
    ListHeadingModule,
    YesNoInputModule,
    ListPageHeaderModule,
    DepartmentSelectModule,
    StatusIndicatorModule,
  ],
  exports: [
    FlexLayoutModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgxDatatableModule,
    TranslateModule,
    CenteredPageModule,
    InputLabelModule,
    ListItemModule,
    ListHeadingModule,
    YesNoInputModule,
    ListPageHeaderModule,
    DepartmentSelectModule,
    StatusIndicatorModule,
  ]
})
export class CommonImportsModule { }
