import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-list-page-header',
  templateUrl: './list-page-header.component.html',
  styleUrls: ['./list-page-header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ListPageHeaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
