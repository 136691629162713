import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, FormControlName, FormGroup, FormGroupDirective, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-yes-no-input',
  templateUrl: './yes-no-input.component.html',
  styleUrls: ['./yes-no-input.component.scss'],
  providers: [
    { 
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => YesNoInputComponent),
      multi: true
    }
  ]
})
export class YesNoInputComponent implements OnInit, ControlValueAccessor {

  constructor(
    private formGroupDirective: FormGroupDirective, 
  ) {
  }

  @Input('value') value: boolean = null
  @Input('disabled') disabled: boolean = false;
  public touched: boolean = false;

  writeValue(obj: any): void {
    if (obj !== false && obj !== true) {
      return;
    }

    this.value = obj
  }
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.propagateTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled
  }

  propagateChange = (_: any) => {};
  propagateTouched = () => {};


  setValue(value: boolean) {
    this.markAsTouched()
    if (this.disabled) {
      return;
    }
    this.value = value
    this.propagateChange(value)
  }

  markAsTouched() {
    if (!this.touched) {
      this.propagateTouched();
      this.touched = true;
    }
  }



  ngOnInit(): void {
    // Listen for the ngSubmit events, to show errors when they need to be shown
    this.formGroupDirective.ngSubmit.subscribe(
      () => {
        this.touched = true
      }
    )
  }

  get showErrors() {
    return this.touched
  }

}
