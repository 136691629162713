export const environment = {
  production: true,

  api: {
    url: "//api-staging.dpoint.io",
    version: "v1"
  },

  version: {
    prefix: "staging.",
    number: "0.2.0",
    suffix: ""
  },

  logoutTimeout: (5 * 60000) // 5 minutes to auto logout

};
